import React, { Fragment, Component } from "react";

class TitleComponent extends Component {
  render() {
    return (
      <Fragment>
        <p className="TitleHunter center">BEST SKIMMER HUNTER</p>
      </Fragment>
    );
  }
}

export default TitleComponent;
