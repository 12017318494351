import React, { Component } from "react";
import "./Styles/Buttons.scss";

class Button extends Component {
  render() {
    return (
      <div id="paypal-button">
        <a
          href="https://electroniccats.com/carrito/?add-to-cart=1541"
          rel="noopener noreferrer"
          className="button-order-now"
        >
          Order now
        </a>

        <p className="footer-p small margin-top-45 txt-small">(Orders after August 11 will be shipped after pre sales shipping has
            finished)</p>
      </div>
    );
  }
}

export default Button;
