import React, { Fragment, Component } from "react";

import './Styles/Card.scss'
class Card extends Component {
  slider(){
    if(!this.props.content){
      return <img className="img-card" style={this.props.style} id={this.props.id} src={this.props.img} alt={this.props.alt}/>            
    }else{
      return this.props.content
    }
  }
  render() {
    return (
      <Fragment>
        <div className="card">
          <div className="bg-img hunter-img">
            {this.slider()}
          </div>
          <div className="content">
            <h4>{this.props.description}</h4>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Card;
