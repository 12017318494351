import React, { Fragment, Component } from "react";
import { NavLink } from "react-router-dom";
/**
 * Assets
 */
import logo from "../../assets/images/hunter-cat.svg";
import "../Styles/Navbar.scss";

class Navbar extends Component {
  render() {
    return (
      <Fragment>
        <div className="line-gold">

        </div>
        <div className="container Navbar">
          <nav>
            <NavLink to="/" className="NavLink">
              HOME
            </NavLink>
            <NavLink to="/About" className="NavLink about">
              ABOUT HUNTER CAT
            </NavLink>
            <NavLink to="/Faq" className="NavLink faq">
              FAQ
            </NavLink>
          </nav>
          <img src={logo} alt="logo" className="Navbar__Logo" />
        </div>
      </Fragment>
    );
  }
}

export default Navbar;
