import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

/**
 * Layoutd
 */
import LayoutHome from "./LayoutHome.jsx";
import LayoutAbout from "./LayoutAbout.jsx";
import LayoutFaq from "./LayoutFaqs.jsx";

class App extends Component {
  render() {
    return (
      <Router>
        <header className="App-header" />
        <div>
          <Route exact path="/" component={LayoutHome} />
          <Route exact path="/About" component={LayoutAbout} />
          <Route exact path="/Faq" component={LayoutFaq} />
        </div>
      </Router>
    );
  }
}

export default App;
