import React, { Component } from "react";

/**
 * Components
 */
import Button from "./Button.jsx";
/**
 * Assets
 */
import facebook from "../assets/images/facebook-icon.svg";
import twitter from "../assets/images/twitter-icon.svg";
import instagram from "../assets/images/instagram-icon.svg";

import "./Styles/Footer.scss";

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="grid-container grid-area-footer">
          <div className="header margin-top-90">
            <Button />
          </div>
          <div className="social">
            <a href="https://twitter.com/electronicats">
              <img src={twitter} className="social-media" alt="" />
            </a>
            <a href="https://www.facebook.com/electroniccats/?ref=br_rs">
              <img
                src={facebook}
                className="social-media icon-facebook"
                alt=""
              />
            </a>
            <a href="https://www.instagram.com/electroniccats/">
              <img src={instagram} className="social-media" alt="" />
            </a>
          </div>
          <div className="content-price">
            
            <p className="price-footer">!THANKS</p>
            <p className="footer-p small"><span role="img">🐈</span></p>

          </div>
        </div>
        <div className="subtitle-about txt-small">
          
          Salvador Mendoza and Electronic Cats do NOT take any responsibility;
          adding that Salvador Mendoza and Electronic Cats are NOT liable for
          any damage caused through use of Hunter Cat, be it indirect, special,
          incidental or consequential damages.
        </div>
      </div>
    );
  }
}

export default Footer;
