import React, { Component } from "react";

/**
 * Components
 */
import Navbar from "../components/Navbar/Navbar.jsx";
import TitleComponent from "../components/TitleHunter.jsx";
import GridCards from "../components/GridCards.jsx";
import Footer from "../components/Footer.jsx";
import PressaleRelease from '../components/PressRelease.jsx'
export default class LayoutHome extends Component {
  render() {
    return (
      <div className="wrapper">
        <Navbar />
        <TitleComponent />
        <GridCards />
        <Footer />
        <PressaleRelease/>
      </div>
    );
  }
}
