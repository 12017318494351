import React, { Fragment, Component } from "react";
import AwesomeSlider from "react-awesome-slider";

import "react-awesome-slider/src/core/styles.scss";
import "./Styles/GridCards.scss";

/**
 * Assets
 */
import hand from "../assets/images/hand.svg";
import hunterChip from "../assets/images/hunter-cat-chip.svg";
import creditCard from "../assets/images/credit-card.svg";

/**
 * Banner
 */
import banner_1 from "../assets/images/banner_1.jpg";
import banner_2 from "../assets/images/banner_2.jpg";
import banner_3 from "../assets/images/banner_3.jpg";
import banner_4 from "../assets/images/banner_4.jpg";
import banner_5 from "../assets/images/banner_5.jpg";
import banner_6 from "../assets/images/banner_6.jpg";

/**
 * Components
 */
import Card from "./Card.jsx";

/**
 * Slide images
 */
const slideImages = [
  banner_1,
  banner_5,
  banner_3,
  banner_4,
  banner_2,
  banner_6
];

const Slideshow = () => {
  return (
    <AwesomeSlider>
      <div>
        <img className="slide-image" src={slideImages[0]} alt="img1" />
      </div>
      <div>
        <img className="slide-image" src={slideImages[2]} alt="img2" />
      </div>
      <div>
        <img className="slide-image" src={slideImages[3]} alt="img3" />
      </div>
      <div>
        <img className="slide-image" src={slideImages[4]} alt="img4" />
      </div>
      <div>
        <img className="slide-image" src={slideImages[5]} alt="img5" />
      </div>
    </AwesomeSlider>
  );
};

class GridCards extends Component {
  render() {
    return (
      <Fragment>
        <div className="grid-container grid-template-colums-2">
          <Card img={hand} description="PROTECT YOUR BANK CARDS INFORMATION" />
          <Card
            img={hunterChip}
            description="DETECT MALICIOUS CARD READER IN A SECOND"
          />

          <Card
            img={creditCard}
            description="COMPATIBLE WHITH ALL MAGNETIC STRIPE CARD READERS"
          />
          <Card
            content={Slideshow()}
            description="HUNTER CAT $35 USD"
          />
        </div>
      </Fragment>
    );
  }
}

export default GridCards;
