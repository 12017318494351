import React, { Component, Fragment } from "react";

/**
 * Assets
 */
import hacksterLogo from "../assets/images/hackster.png";
import heraldo from "../assets/images/elheraldo.png";
import gizmodo from "../assets/images/gizmodo.png";

import hunter_logo from "../assets/images/hunter-cat-logo.svg";

import "./Styles/pressrelease.scss";

export default class PressRealease extends Component {
  render() {
    return (
      <Fragment>
        <div className="grid-block">
          <h3>They're talking about us <br />👇</h3>
        </div>
        <div className="grid-container grid-template-colums-3 items-center">
          <a href="https://blog.hackster.io/protect-your-information-at-atms-with-electronic-cats-hunter-cat-d738e3e817ca">
            <img
              className="img-pressrelease img-pressrelease-left"
              src={hacksterLogo}
              alt="Hacksterio logo"
            />
          </a>
          <a href="https://heraldodemexico.com.mx/tecnologia/tarjeta-electronica-detectar-cajeros-falsos-clonan-tarjetas-banco/?fbclid=IwAR2h8x8T1a9uUcmhEM-zXySqyrHa3PB4DeLGODcetFzewa3-dhPxIc-rSNg">
            <img
              className="img-pressrelease margin-top-25"
              src={heraldo}
              alt="El Heralod logo"
            />
          </a>
          <a className="grid-column-2" href="https://www.gizmodo.jp/2019/07/anti-atm-skimmer-card-hunter-cat.html">
            <img
              className="img-pressrelease grid-column-2"
              src={gizmodo}
              alt="El Heralod logo"
            />
          </a>
          <div className="logo-hunter-cat-about">
            <img src={hunter_logo} alt="" className="hunter-logo" />
          </div>
        </div>
      </Fragment>
    );
  }
}
